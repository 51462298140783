import React from "react";
import FsLightbox from "fslightbox-react";
import { Portal } from "react-portal";

const Lightbox = React.memo(({ lightboxController, lightBoxSources }) => {
    return (
        <Portal>
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={lightBoxSources}
                slide={lightboxController.slide}
            />
        </Portal>
    );
});

export default Lightbox;
