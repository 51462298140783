import React from "react";
import "./IconButton.scss";

const IconButton = React.memo(
    ({ onClick, className, Icon, width, height, labelText }) => (
        <button
            aria-label={labelText}
            type="button"
            onClick={onClick}
            className={`icon-button ${className}`}
        >
            <Icon height={height} width={width} />
            {labelText && <p className="icon-label">{labelText}</p>}
        </button>
    )
);

export default IconButton;
