import React, { useState } from "react";
import EyeIconSvg from "images/eye-icon.inline.svg";
import DownloadIconSvg from "images/download-icon.inline.svg";
import AudioIconSVG from "images/audio.inline.svg";
import "./ArticleMediaSection.scss";
import Img from "gatsby-image";
import IconButton from "components/icon-button";
import useWindowSize, { breakpoints } from "hooks/useWindowSize";
import { Section } from "react-scroll-section";
import { motion } from "framer-motion";
import Lightbox from "./Lightbox";
import ArrowRight from "../arrow-right";
import RichText from "../rich-text/RichText";
import Heading from "../heading/Heading";
import loadable from "@loadable/component";
const MobileCarousel = loadable(() => import("components/mobile-carousel"));
const Carousel = loadable(() => import("components/carousel"));

const ArticleMediaSection = React.memo(
    ({ articleSections, animateArticleItem, isPressAndMedia, locale }) => {
        const size = useWindowSize();
        const desktopCarousel = size.width > breakpoints.md;

        const [lightboxController, setLightboxController] = useState({
            toggler: false,
            slide: 1,
        });

        const openLightboxOnSlide = (number) => {
            setLightboxController({
                toggler: !lightboxController.toggler,
                slide: number + 1,
            });
        };

        const lightBoxSources = [];

        for (let index = 0; index < articleSections.length; index++) {
            const sectionItem = articleSections[index];

            for (let index = 0; index < sectionItem.assets.length; index++) {
                const asset = sectionItem.assets[index];
                if (asset.mimeType !== "application/pdf") {
                    lightBoxSources.push(
                        asset.url.replaceAll(
                            "https://www.datocms-assets.com/23229/",
                            "https://olafureliasson-net.fra1.cdn.digitaloceanspaces.com/earthspeakr/"
                        )
                    );
                }
            }
        }

        const sections = articleSections.map((section, key) => {
            let linkSlug;

            if (!section.linkToNonArticle && section.moreDetailsLink?.slug) {
                linkSlug = section.moreDetailsLink?.slug;
            } else if (
                section.linkToNonArticle &&
                section.linkToNonArticleSlug
            ) {
                linkSlug = section.linkToNonArticleSlug;
            } else {
                linkSlug = "";
            }

            return (
                !section.link && (
                    <Section
                        key={key}
                        className="section"
                        id={`section-${key}`}
                    >
                        <motion.div variants={animateArticleItem}>
                            {section.title && (
                                <Heading slug={section.title}>
                                    {section.title}
                                </Heading>
                            )}
                            {section.description !== "" &&
                                section.description !== null && (
                                    <RichText
                                        className="section__description"
                                        content={section.description}
                                    />
                                )}

                            {section?.carousel.length !== 0 &&
                                !desktopCarousel && (
                                    <>
                                        <MobileCarousel
                                            slides={section?.carousel}
                                        />
                                    </>
                                )}
                            {section.moreDetailsLinkLabel && (
                                <a
                                    href={`/${locale}/${linkSlug}`}
                                    className="section__more-details-link"
                                >
                                    <ArrowRight color="red" />
                                    {section.moreDetailsLinkLabel}
                                </a>
                            )}

                            {section?.carousel.length !== 0 &&
                                desktopCarousel && (
                                    <>
                                        <Carousel slides={section?.carousel} />
                                    </>
                                )}
                            <div className="section__assets-container">
                                {section.assets.map((asset, key) => (
                                    <React.Fragment key={key}>
                                        {asset.mimeType === "video/mp4" &&
                                        asset.video &&
                                        asset?.video?.mp4Url ? (
                                            <video
                                                className="video-asset"
                                                playsInline
                                                autoPlay
                                                loop
                                                muted
                                                controls
                                            >
                                                <source
                                                    src={asset?.video?.mp4Url}
                                                    type="video/mp4"
                                                />
                                            </video>
                                        ) : (
                                            <div
                                                className={`asset${
                                                    asset.mimeType ===
                                                    "application/pdf"
                                                        ? " asset--pdf"
                                                        : asset.mimeType ===
                                                          "audio/mpeg"
                                                        ? " asset--audio"
                                                        : " "
                                                }`}
                                            >
                                                {asset.mimeType !==
                                                    "application/pdf" && (
                                                    <div className="asset__media-container">
                                                        {(asset.mimeType ===
                                                            "image/jpeg" ||
                                                            asset.mimeType ===
                                                                "image/png") &&
                                                            asset.fluid && (
                                                                <Img
                                                                    imgStyle={{
                                                                        objectFit:
                                                                            isPressAndMedia
                                                                                ? "cover"
                                                                                : "contain",
                                                                    }}
                                                                    style={{
                                                                        position:
                                                                            "absolute",
                                                                    }}
                                                                    fluid={
                                                                        asset.fluid
                                                                    }
                                                                    alt={
                                                                        asset.alt ||
                                                                        "Earth speakr media"
                                                                    }
                                                                />
                                                            )}

                                                        {asset.mimeType ===
                                                            "audio/mpeg" && (
                                                            <div className="audio-svg-container">
                                                                <AudioIconSVG />
                                                            </div>
                                                        )}

                                                        {!section.hideAssetsMetaData && (
                                                            <div className="asset__actions">
                                                                {(asset.mimeType ===
                                                                    "image/jpeg" ||
                                                                    asset.mimeType ===
                                                                        "video/mp4" ||
                                                                    "image/png") && (
                                                                    <IconButton
                                                                        Icon={
                                                                            EyeIconSvg
                                                                        }
                                                                        onClick={() => {
                                                                            openLightboxOnSlide(
                                                                                lightBoxSources.findIndex(
                                                                                    (
                                                                                        sourceValues
                                                                                    ) =>
                                                                                        sourceValues ===
                                                                                        asset.url.replaceAll(
                                                                                            "https://www.datocms-assets.com/23229/",
                                                                                            "https://olafureliasson-net.fra1.cdn.digitaloceanspaces.com/earthspeakr/"
                                                                                        )
                                                                                )
                                                                            );
                                                                        }}
                                                                        type="button"
                                                                        className="asset__action-button"
                                                                    />
                                                                )}
                                                                <a
                                                                    className="asset__action-button"
                                                                    href={`${asset.url.replaceAll(
                                                                        "https://www.datocms-assets.com/23229/",
                                                                        "https://olafureliasson-net.fra1.cdn.digitaloceanspaces.com/earthspeakr/"
                                                                    )}`}
                                                                    download={
                                                                        asset.filename
                                                                    }
                                                                    alt={
                                                                        asset.filename
                                                                    }
                                                                    type={
                                                                        asset.mimeType
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    <DownloadIconSvg />
                                                                </a>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                                {asset.mimeType ===
                                                    "application/pdf" && (
                                                    <div className="asset__text">
                                                        <h3>{asset.title}</h3>
                                                    </div>
                                                )}

                                                {!section.hideAssetsMetaData && (
                                                    <div className="asset__meta">
                                                        <span className="asset__name">
                                                            {asset.mimeType ===
                                                            "application/pdf" ? (
                                                                <>
                                                                    <a
                                                                        href={`${asset.url.replaceAll(
                                                                            "https://www.datocms-assets.com/23229/",
                                                                            "https://olafureliasson-net.fra1.cdn.digitaloceanspaces.com/earthspeakr/"
                                                                        )}`}
                                                                        type={
                                                                            asset.mimeType
                                                                        }
                                                                        download={
                                                                            asset.filename
                                                                        }
                                                                        alt={
                                                                            asset.filename
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <DownloadIconSvg />
                                                                    </a>
                                                                </>
                                                            ) : asset.title ? (
                                                                asset.title
                                                            ) : (
                                                                asset.basename
                                                            )}
                                                        </span>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))}
                            </div>
                        </motion.div>
                    </Section>
                )
            );
        });

        return (
            <>
                {sections}
                <Lightbox
                    lightboxController={lightboxController}
                    lightBoxSources={lightBoxSources}
                />
            </>
        );
    }
);

export default ArticleMediaSection;
